<template>
	<div id="inSchool">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>德育</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">招生管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="suguan">
					<div class="title u-f-item u-f-jsb">
						<div>共{{ total }}条数据</div>
						<div class="u-f-item">
							<el-button style="margin-left: 10px;" size="small" @click="ewmShow=true">入学填报</el-button>
							<el-button style="margin-left: 10px;" size="small" type="primary" @click="updateVisible=true">导入</el-button>
							<el-button style="margin-left: 10px;" size="small" type="primary" @click="AdmissionsWriteExports">导出</el-button>
						</div>
					</div>
					<el-table
              :data="list"
              ref="multipleTable"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              style="width: 95vw;border: 1px solid #EEEEEE;"
              v-loading="loading"
          >
						<el-table-column prop="student_num" label="学籍号" fixed width="160" align="center"></el-table-column>
						<el-table-column label="学生姓名" fixed align="center" prop="realname"></el-table-column>
						<el-table-column label="性别" fixed align="center" prop="remark">
							<template slot-scope="scope">
								<div>{{ scope.row.sex === '1' ? '男' : '女' }}</div>
							</template>
						</el-table-column>
						<el-table-column prop="idcard" width="200px" label="身份证号码" align="center"></el-table-column>
						<el-table-column label="身份" prop="end_time" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.sex === '1'">群众</div>
								<div v-if="scope.row.sex === '2'">团员</div>
								<div v-if="scope.row.sex === '3'">党员</div>
							</template>
						</el-table-column>
						<el-table-column label="毕业学校" prop="former_school" width="150" align="center"></el-table-column>
						<el-table-column label="就读方式" prop="end_time" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.studying_way === '1'">住校</div>
								<div v-if="scope.row.studying_way === '2'">走读</div>
							</template>
						</el-table-column>
						<el-table-column label="出生日期" prop="birthday" width="150" align="center">
              <template v-slot="{ row }">
                {{row.birthday|date('YYYY-MM-DD')}}
              </template>
            </el-table-column>
						<el-table-column label="民族" prop="nation" align="center"></el-table-column>
						<el-table-column label="籍贯" width="100"  prop="native_place" align="center"></el-table-column>
						<el-table-column label="户口地址" width="200"  prop="household_address" align="center"></el-table-column>
						<el-table-column label="所属派出所" width="160"  prop="police_station" align="center"></el-table-column>
						<el-table-column label="户口类型" width="120" prop="end_time" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.household_type == '1'">农业户口</div>
								<div v-if="scope.row.household_type == '2'">非农业户口</div>
							</template>
						</el-table-column>
						<el-table-column label="居住地类型" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.address_type == '1'">农村</div>
								<div v-if="scope.row.address_type == '2'">乡镇非农</div>
								<div v-if="scope.row.address_type == '3'">城市</div>
								<div v-if="scope.row.address_type == '4'">县城</div>
							</template>
						</el-table-column>
						<el-table-column label="学生来源" width="120" prop="end_time" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.student_source == '1'">应届</div>
								<div v-if="scope.row.student_source == '2'">往届</div>
							</template>
						</el-table-column>
						<el-table-column label="专业简称" width="150" prop="specialty_name" align="center"></el-table-column>
						<el-table-column label="手机号码" width="150" prop="phone" align="center"></el-table-column>
						<el-table-column label="监护人1-姓名" width="150" prop="name1" align="center"></el-table-column>
						<el-table-column label="监护人1-电话" width="150" prop="tel1" align="center"></el-table-column>
						<el-table-column label="监护人1-关系" width="150" prop="relationship1" align="center"></el-table-column>
						<el-table-column label="监护人2-姓名" width="150" prop="name2" align="center"></el-table-column>
						<el-table-column label="监护人2-电话" width="150" prop="tel2" align="center"></el-table-column>
						<el-table-column label="监护人2-关系" width="150" prop="relationship2" align="center"></el-table-column>
						<el-table-column label="操作" width="100" align="center" fixed="right">
							<template slot-scope="scope">
								<el-button @click="openDialog(scope.row)" type="text" size="small">编辑</el-button>
								<!-- <el-button style="color: red;" @click="del(scope.row.id)" type="text" size="small">删除</el-button> -->
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
			<!-- 导入 -->
			<el-dialog title="导入" :visible.sync="updateVisible" width="500px" :before-close="handleClose">
				<div class="update_box">
					<div class="u-f" style="margin-top: 20px;">
						<div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">导入模板</div>
						<el-button size="small" @click="moban">点击下载导入模板</el-button>
					</div>
					<div class="u-f" style="margin-top: 20px;">
						<div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">数据录入</div>
						<div>
							<el-button size="mini" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">点击上传</el-button>
							<div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>{{file.name}}
								</div>
								<i class="el-icon-circle-check" style="color: #67C23A;"></i>
								<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
							</div>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="updateVisible=false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="gengxin">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑信息 -->
			<el-dialog title="编辑信息" :visible.sync="addShow" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							姓名
						</div>
						<el-input v-model="info.realname" placeholder="请输入姓名" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							性别
						</div>
						<el-radio-group v-model="info.sex">
							<el-radio :label="1">男</el-radio>
							<el-radio :label="2">女</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							身份证号码
						</div>
						<el-input v-model="info.idcard" placeholder="请输入身份证号码" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							毕业学校
						</div>
						<el-input v-model="info.former_school" placeholder="请输入毕业学校" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							就读方式
						</div>
						<el-radio-group v-model="info.studying_way">
							<el-radio :label="1">住校</el-radio>
							<el-radio :label="2">走读</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							专业简介
						</div>
						<el-input v-model="info.specialty_name" placeholder="请输入专业简介" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							身份
						</div>
						<el-radio-group v-model="info.identity">
							<el-radio :label="1">群众</el-radio>
							<el-radio :label="2">团员</el-radio>
							<el-radio :label="3">党员</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							出生日期
						</div>
						<el-date-picker value-form="yyyy-MM-dd" v-model="info.birthday" type="date" placeholder="选择出生日期日期"></el-date-picker>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							民族
						</div>
						<el-input v-model="info.nation" placeholder="请输入民族" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							籍贯
						</div>
						<el-input v-model="info.native_place" placeholder="请输入籍贯" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							详细地址
						</div>
						<el-input v-model="info.household_address" placeholder="请输入户口所在地详细地址" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							所属派出所
						</div>
						<el-input v-model="info.police_station" placeholder="请输入所属派出所" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							户口类型
						</div>
						<el-radio-group v-model="info.household_type">
							<el-radio :label="1">农业户口</el-radio>
							<el-radio :label="2">非农业户口</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							居住地类型
						</div>
						<el-radio-group v-model="info.address_type">
							<el-radio :label="1">农村</el-radio>
							<el-radio :label="2">乡镇非农</el-radio>
							<el-radio :label="3">城市</el-radio>
							<el-radio :label="4">县城</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							学生来源
						</div>
						<el-radio-group v-model="info.student_source">
							<el-radio :label="1">应届</el-radio>
							<el-radio :label="2">往届</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							手机号码
						</div>
						<el-input v-model="info.phone" placeholder="请输入手机号码" style="width: 70%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							身份证照片
						</div>
						<el-upload
						  class="upload-demo"
						  action="http://school.blzzxx.cn/v1/school_api/common/upload"
						  :on-success="handlePreview"
						  :on-remove="handleRemove"
						  :file-list="info.idcard_images_list"
						  list-type="picture">
						  <el-button size="small" type="primary">点击上传</el-button>
						  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
						</el-upload>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							户口本照片
						</div>
						<el-upload
						  class="upload-demo"
						  action="http://school.blzzxx.cn/v1/school_api/common/upload"
						  :on-success="handlePreview1"
						  :on-remove="handleRemove1"
						  :file-list="info.residence_booklet_images_list"
						  list-type="picture">
						  <el-button size="small" type="primary">点击上传</el-button>
						  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
						</el-upload>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							监护人1-姓名
						</div>
						<el-input v-model="info.name1" placeholder="请输入监护人姓名" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							监护人1-电话
						</div>
						<el-input v-model="info.tel1" placeholder="请输入监护人电话" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							监护人1-关系
						</div>
						<el-input v-model="info.relationship1" placeholder="请输入监护人关系" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							监护人2-姓名
						</div>
						<el-input v-model="info.name2" placeholder="请输入监护人姓名" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							监护人2-电话
						</div>
						<el-input v-model="info.tel2" placeholder="请输入监护人电话" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							监护人2-关系
						</div>
						<el-input v-model="info.relationship2" placeholder="请输入监护人关系" style="width: 70%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addShow = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
			
			<!-- 编辑信息 -->
			<el-dialog title="二维码打印" :visible.sync="ewmShow" width="500px" :before-close="handleClose">
				<div class="edit-box u-f-justify" ref="print" style="height: 300px;">
					<img src="/static/image/rxtb.png" width="180px" height="180px">
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="ewmShow = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="print">打印</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			updateVisible:false,
			file: '',
			fileUrl:'',
			ewmShow:false,
			addShow: false,
			page: 1,
			limit: 10,
			list: [],
			total: 0,
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
			, },
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			},
      loading: true,
			value2: [],
			info: '',
			idcard_images:[],
			residence_booklet_images:[],
			ids:[]
		};
	},
	mounted() {
		this.allLeaveSchoolList();
	},
	methods: {
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		gengxin(){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			this.$api.setting.AdmissionsWriteImports(formData).then(res=>{
				if(res.data.code==1){
					this.$message.success('导入成功');
					this.file = '';
					this.updateVisible = false;
					this.allLeaveSchoolList();
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		moban(){
			location.href = 'http://school.blzzxx.cn/template/学籍导入模板.xlsx'
		},
		getFile(e){
			if(this.$refs.filElem.files.length){
				this.file = this.$refs.filElem.files[0]
			}
		},
		delFile(){
			this.file = '';
			this.$refs.filElem.value = '';
		},
		print(){
			this.$print(this.$refs.print)
		},
		handleRemove(file, fileList) {
			for(let i in this.info.idcard_images_text){
				if(this.info.idcard_images_text[i] == file.url){
					this.idcard_images.splice(i,1)
				}
			}
		},
		handlePreview(res, file) {
			if (res.code == 1) {
				this.idcard_images = this.idcard_images.concat(res.data.url)
			} else {
				this.$message.error(res.msg);
			}
		},
		handleRemove1(file, fileList) {
			for(let i in this.info.residence_booklet_images_text){
				if(this.info.residence_booklet_images_text[i] == file.url){
					this.residence_booklet_images.splice(i,1)
				}
			}
		},
		handlePreview1(res, file) {
			if (res.code == 1) {
				this.residence_booklet_images = this.residence_booklet_images.concat(res.data.url)
			} else {
				this.$message.error(res.msg);
			}
		},
		handleClose(done) {
			done();
		},
		allLeaveSchoolList() {
      this.loading = true
			this.$api.setting.AdmissionsWriteList({ page: this.page, limit: this.limit })
          .finally(() => this.loading = false)
          .then(res => {
            if (res.data.code === 1) {
              this.total = res.data.data.total;
              this.list = res.data.data.rows;
            }
          });
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
      console.log('change')
			this.allLeaveSchoolList();
		},
		AdmissionsWriteExports() {
			this.$api.setting
				.AdmissionsWriteExports()
				.then(res => {
					if (res.data.code === 1) {
						location.href = res.data.data.url;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		openDialog(item) {
			this.info = item;
			this.addShow = true;
		},
		submit(){
			this.$api.setting.editAdmissionsWrite({
				id:this.info.id,
				realname:this.info.realname,
				school_campus_id:this.info.school_campus_id,	
				sex:this.info.sex,
				idcard:this.info.idcard,
				identity:this.info.identity,
				birthday:this.info.birthday,
				nation:this.info.nation,
				native_place:this.info.native_place,	
				phone:this.info.phone, 	
				name1:this.info.name1, 	
				relationship1:this.info.relationship1, 	
				tel1:this.info.tel1,	
				name2:this.info.name2,	
				relationship2:this.info.relationship2,
				tel2:this.info.tel2,
				former_school:this.info.former_school,
				studying_way:this.info.studying_way,	
				household_address:this.info.household_address,	
				police_station:this.info.police_station,	
				household_type:this.info.household_type,
				address_type:this.info.address_type,	
				student_source:this.info.student_source,
				idcard_images:this.idcard_images.toString(),	
				residence_booklet_images:this.residence_booklet_images.toString(),
				specialty_name:this.info.specialty_name
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('编辑成功');
					this.allLeaveSchoolList()
					this.addShow = false;
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		delClass(id){
			let _this = this;
			if(this.ids.length){
				this.$confirm('确定要删除？','提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(()=>{
					this.$api.setting.delAdmissionsWrite({
						id:this.ids
					}).then(res=>{
						if (res.data.code == 1) {
							_this.$message.success('删除成功');
							_this.allLeaveSchoolList();
						} else {
							_this.$message.error(res.data.msg);
						}
					})
				})
			}else{
				this.$message.error('请选择要删除的数据')
			}
		}
	}
};
</script>

<style lang="scss">
#inSchool {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		.left {
			border-right: 1px solid #eeeeee;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			width: 100%;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
		.suguan {
			padding: 15px 50px 15px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		overflow-y: auto;
		max-height: 500px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item {
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
		.update_box {
			padding: 40px 20px;
			.update_item {
				background-color: #f6f8fb;
				padding: 20px;
				a {
					color: #007aff;
					text-decoration: none;
				}
			}
			.upload-file{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.file_box{
				padding: 5px;
				.el-icon-circle-check{
					display: block;
				}
				.el-icon-circle-close{
					display: none;
				}
			}
			.file_box:hover{
				color: #007AFF;
				background-color: #F6F8FB;
				.el-icon-circle-check{
					display: none;
				}
				.el-icon-circle-close{
					display: block;
				}
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}

.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el_option {
	display: none !important;
}
</style>
